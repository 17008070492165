import type { NextPage } from 'next';
import Image from 'next/image';

const Home: NextPage = () => (
  <div className="home-wrapper">
    <div className="content-wrapper">
      <Image src="/logo.png" width="360" height="177" alt="OJL maestro" />
      <h2>Integraatio</h2>
    </div>
  </div>
);

export default Home;
